import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, numberOfPages, humanPageNumber } =
    pageContext

  return (
    <Wrapper>
      <div className="pager-comp">
        {previousPagePath && (
          <span className="zurueck">
            <Link to={previousPagePath}>{"< "}Zurück</Link>
          </span>
        )}
        <span className="mitte">
          {humanPageNumber}
          {" / "}
          <Link to={`/inspiration/${numberOfPages}`}>{numberOfPages}</Link>
        </span>
        {nextPagePath && (
          <span className="weiter">
            <Link to={nextPagePath}>Weiter{" >"}</Link>
          </span>
        )}
      </div>
    </Wrapper>
  )
}

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

const Wrapper = styled.section`
  .pager-comp {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .zurueck {
    grid-column: 2 / 5;
    grid-row: 1;
    justify-items: end;
  }
  .weiter {
    grid-column: 4 / 5;
    grid-row: 1;
    justify-items: start;
  }
  .mitte {
    grid-column: 3 / 5;
    grid-row: 1;
    justify-items: center;
  }
`

export default Pager
