import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Pager from "../components/Pager"
import slugify from "slugify"
import deChar from "../utils/deChar"

const InspirationenTemplate = ({ data, pageContext }) => {
  const products = data.allProdukteKurzJson.edges
  //console.log(products)
  return (
    <Layout>
      <SEO title="Rund ums Reisen" />
      <main className="page">
        <h2>Inspiration</h2>
        <h5>
          Hast du eine Ideen, aber noch keine konkrete Vorstellung? <br />
          Entdecke diese inspirierenden Möglichkeiten!
        </h5>
        <Pager pageContext={pageContext} className="recipe-tags" />
        <div className="recipes-list">
          {products.map(product => {
            const {
              id,
              product_name,
              brand_name,
              aw_image_url,

              aw_product_id,
            } = product.node
            const pathToImage = aw_image_url //getImage(image)
            const deDE = deChar(product_name)
            const verbessert = deDE
              .replace(",", "-")
              .replace(".", "-")
              .replace("+", "-")
              .replace("(r)", "")
              .replace("(", "")
              .replace(")", "")
              .replace("\u00ae", "")
              .replace("\u2122", "")

            const slug = slugify(verbessert, {
              lower: true,
            })
            return (
              <Link
                key={id}
                to={`/inspiration/${aw_product_id}`}
                className="recipe"
              >
                <div>
                  <img
                    src={aw_image_url}
                    alt={product_name}
                    className="recipe-img"
                  />
                  <p>{product_name}</p>
                  {/*Vorbereitung : {ideetip.prepTime} | Dauer : {ideetip.durationTime}
                  <p>{brand_name}</p>*/}
                </div>
              </Link>
            )
          })}
        </div>
        <Pager pageContext={pageContext} className="recipe-tags" />
      </main>
    </Layout>
  )
}

InspirationenTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allProdukteKurzJson(skip: $skip, limit: $limit) {
      edges {
        node {
          id
          aw_image_url
          brand_name
          product_name
          ean
          aw_product_id
        }
      }
    }
  }
`

export default InspirationenTemplate
